@import "utility";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');



body { font-family: 'Inter', sans-serif; line-height: 1.5; }
.ly-wrapper a, .ly-wrapper .btn-link { text-decoration: none;}
[role="button"] { cursor: pointer; }
.ly-wrapper h1, .ly-wrapper .h1 { font-weight: 700; margin-top: 0; margin-bottom: 16px; } //48
.ly-wrapper h2, .ly-wrapper .h2 { font-size: 36px; font-weight: 700; margin-top: 0; margin-bottom: 32px; line-height: 1.125; } //36
.ly-wrapper h3, .ly-wrapper .h3 { font-size: 24px; font-weight: 700; margin-top: 0; } //24
.ly-wrapper h4, .ly-wrapper .h4 { font-size: 18px; font-weight: 700; margin-top: 0; } //18
.ly-wrapper p { font-size: 18px; line-height: 1.5; margin-top: 0; margin-bottom: 24px; color: #666666;}

button { border:0; padding: 5px;  font-family: 'Inter'; font-style: normal; font-weight: 500; font-size: 12px; cursor: pointer; }
.ly-btn { font-family: 'Inter'; font-style: normal; font-weight: 500; font-size: 12px; line-height: 16px; color: #000000; padding: 8px 40px; border-radius: 10px; display: inline-block; border:0; cursor: pointer; }
.ly-btn-lg { font-size: 16px; padding: 16px 38px; }
.ly-btn-xlg { font-size: 16px; padding: 20px 24px; }
.ly-btn-primary { background: linear-gradient(200deg, #2400FF 15.03%, #BC10D5 93.09%); color: #ffffff; }
.ly-btn-primary:hover { background: linear-gradient(228.47deg, #BC10D5 15.03%, #2400FF 93.09%);}
.ly-btn.ly-btn-primary:disabled, .ly-btn.ly-btn-primary.disabled, fieldset:disabled .ly-btn.ly-btn-primary { background: $light;}
.ly-btn-dark { background: #000; color: #ffffff; }
.ly-btn-link { background: transparent;}

svg { display: inline-block; vertical-align: middle;}
.ly-shoplivebtn { position: fixed; bottom: 0; right: 0; padding: 24px; }
.ly-welcomebox { width: 375px; height: 630px; background: #fff; border-radius: 10px; position: fixed; right: 24px; bottom: 24px; text-align: center; padding: 24px; display: flex;
flex-direction: column; box-shadow: 0 0 5px rgba(0,0,0,0.3); justify-content: center; }
.ly-close-icon { position: absolute; right: 0; top: 0; }
.ly-close-icon .ly-btn { padding: 14px; background:transparent }
.ly-welcomebox .ly-imgbox { width: 80px; height: 80px; border-radius: 100%; overflow: hidden; margin: 0 auto 16px; }
.ly-welcomebox .ly-imgbox img { width: 100%; height: 100%; object-fit: cover; }

.ly-container { max-width: 1440px; padding: 0 12px; margin: 0 auto; }
.ly-header { padding: 32px 0; }
.livevideocontainer { display: flex; justify-content: space-between; margin: 0 auto; max-width: 1312px; position: relative; }
.videobox { flex: 1; }
.livecartbox { background: #fff; flex: 0 0 328px; margin-left: 16px; max-width: 328px; border-radius: 10px; }
hr { border: 0; border-top: 1px solid; color: #000; margin: 16px 0; opacity: .25; }
.ly-cart { border: 1px solid #ccc; border-radius: 10px; padding: 16px; height: 100%; }
.ly-cart .titlebox { display: flex; align-items: center; justify-content: space-between; }
.ly-cart .titlebox .ly-btn { padding: 8px 16px;}
.ly-prolistitem { display: flex; align-items: center; margin: 16px 0; }
.ly-prolistitem .imgbox { border-radius: 8px; flex: 0 0 40px; height: 40px; overflow: hidden; width: 40px; margin-right: 8px; }
.ly-prolistitem .imgbox img { width: 100%; height: 100%; object-fit: cover; }
#root { position: relative; z-index: 9; }
.ly-wrapper :focus-visible {
	outline: 0;
	outline-offset: 0;
	box-shadow: none;
}
.ly-error-msg { 
	border:1px solid #dc3545;
	border-radius: 10px;
	color: #dc3545;
	padding: 16px;
	background: rgba(217,217,217,0.25);
	text-align: center;
	margin-bottom: 16px;
	font-size: 14px;
}

.resvideo { 
	padding-bottom: 56.25%; position: relative;
}
.resvideo video { width: 100%; height: 100%; position: absolute; left: 0; top: 0;}
.ly-checkbox {
    text-align: left;
    font-size: 12px;
    color: #666666;
    line-height: 16px;
}
.ly-checkbox input[type="checkbox"] {
    vertical-align: middle;
    margin-right: 8px;
}

@media only screen and ( max-width : 767px ) { 
    .ly-welcomebox { width: 100%; height: 100%; right: 0; bottom: 0; overflow-y: auto; }    
}