@import "variables";
// @import "~bootstrap/scss/bootstrap";

*, ::after, ::before { box-sizing: border-box;}

.ly-position-relative { position: relative; z-index: 2;}
.ly-position-absolute { position: absolute;}
.ly-d-flex { display: flex;}

.ly-fs-1 { font-size: 48px;} //48
.ly-fs-2 { font-size: 36px;} //36
.ly-fs-3 { font-size: 24px;} // 24
.ly-fs-4 { font-size: 18px;} // 18
.ly-fs-5 { font-size: 16px;} // 16
.ly-fs-6 { font-size: 32px;} // 32
.ly-fs-7 { font-size: 14px;} // 14
.ly-fs-8 { font-size: 12px;} // 12
.ly-fs-9 { font-size: 10px;} //10

.ly-fw-normal { font-weight: normal;}
.ly-fw-semibold { font-weight: 500;}
.ly-fw-bold { font-weight: 700;}

.ly-w-100 { width: 100%;}

.ly-mb-0 { margin-bottom: 0px;}
.ly-mb-1 { margin-bottom: 4px;}
.ly-mb-2 { margin-bottom: 8px;}
.ly-mb-3 { margin-bottom: 16px;}
.ly-mb-4 { margin-bottom: 24px;}
.ly-mb-5 { margin-bottom: 48px;}

.ly-mt-0 { margin-top: 0px;}
.ly-mt-1 { margin-top: 4px;}
.ly-mt-2 { margin-top: 8px;}
.ly-mt-3 { margin-top: 16px;}
.ly-mt-4 { margin-top: 32px;}
.ly-mt-5 { margin-top: 48px;}

.ly-me-0 { margin-right: 0px;}
.ly-me-1 { margin-right: 4px;}
.ly-me-2 { margin-right: 8px;}
.ly-me-3 { margin-right: 16px;}
.ly-me-4 { margin-right: 32px;}
.ly-me-5 { margin-right: 48px;}

.ly-ms-auto { margin-left: auto;}
.ly-ms-0 { margin-left: 0px;}
.ly-ms-1 { margin-left: 4px;}
.ly-ms-2 { margin-left: 8px;}
.ly-ms-3 { margin-left: 16px;}
.ly-ms-4 { margin-left: 32px;}
.ly-ms-5 { margin-left: 48px;}

.ly-mt-n2 { margin-top: -8px;}
.ly-mt-n3 { margin-top: -16px;}
.ly-mt-n4 { margin-top: -32px;}
.ly-mt-n5 { margin-top: -48px;}

.ly-mb-n2 { margin-bottom: -8px;}
.ly-mb-n3 { margin-bottom: -16px;}
.ly-mb-n4 { margin-bottom: -32px;}
.ly-mb-n5 { margin-bottom: -48px;}



.ly-text-start { text-align: left;}
.ly-text-center { text-align: center;}
.ly-text-end { text-align: left;}

.ly-text-black-50 { opacity: 0.5;}
.img-fluid { max-width: 100%; }
.ly-d-none { display: none; }
.error { color: $danger; }

.ly-form-control {
	-webkit-appearance: none;
	appearance: none;
	background-clip: padding-box;
	background-color: #fff;
	border: 1px solid #d9d9d9;
	border-radius: 10px;
	color: #000;
	display: block;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.5;
	padding: 12px 16px;
	transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
	width: 100%;
}
.ly-form-label {
	color: #999;
	margin-bottom: 8px;
    display: inline-block;
}
.form-control-cover { position: relative;} 
.form-control-cover .ly-form-label { position: absolute; pointer-events: none; transform: translate(0, 16px) scale(1); transform-origin: top left; transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
line-height: 1; left: 16px; top:0; z-index: 1; font-size: 14px; }
.form-control-cover .ly-form-label.filled,
.form-control-cover:focus-within .ly-form-label  { transform: translate(0px, 8px) scale(0.8); }
.form-control-cover .ly-form-select, .form-control-cover .ly-form-control { padding: 20px 48px 6px 16px; }
